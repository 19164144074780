import {
  ReceiptIcon,
  HelpIcon,
  HeartHandshakeIcon,
  PlaneIcon,
  HotelServiceIcon,
  CarIcon,
  TrainIcon,
  BellIcon,
  FolderIcon,
  Book2Icon,
  DashboardIcon,
  BoxIcon,
} from "vue-tabler-icons";
import { useAuthStore } from "@/stores/auth";
import { useIataStore } from "@/stores/iata";

export interface menu {
  header?: string;
  title?: string;
  icon?: any;
  to?: string;
  divider?: boolean;
  chip?: string;
  chipColor?: string;
  chipVariant?: string;
  chipIcon?: string;
  children?: menu[];
  disabled?: boolean;
  subCaption?: string;
  class?: string;
  extraclass?: string;
  type?: string;
  isHide?: boolean;
}

const auth: any = useAuthStore();
const iata: any = useIataStore();

// Initialisation de hasHotelType avec false
const hasHotelType = ref(false);
const isUettr = ref(false);

const isUettrFunction = () => {
  if (auth?.getUserDetails?.groups.includes("Metis")) {
    return true;
  } else if (
    iata?.iata &&
    iata?.iata?.length > 0 &&
    (iata?.iata[0]?.name === "AdenisPDV" ||
      iata?.iata[0]?.name === "Tourcom test siege" ||
      auth?.getUserDetails?.email === "reouven@everbloo.io" ||
      auth?.getUserDetails?.email === "bmolle@metisdigital.io" ||
      auth.getUserDetails.email === "sonia@tourcom.fr" ||
      iata?.iata[0]?.optionHasAgence.some(
        (option: any) => option.type === "UETTR"
      ))
  ) {
    return true;
  }
  return false;
};
// Surveillance des changements de iata.getIata car il est pas initialisé au montage
watch(
  () => iata.getIata,
  (newIata) => {
    hasHotelType.value =
      newIata?.[0]?.optionHasAgence?.some(
        (option: any) => option.type === "Hotel"
      ) || false;
    isUettr.value = isUettrFunction();
  },
  { immediate: true } // Exécute le watch dès le montage
);

const runtimeConfig = useRuntimeConfig();
const horizontalItems: ComputedRef<menu[]> = computed((): menu[] => {
  return [
    {
      title: "reservation",
      icon: HeartHandshakeIcon,
      to: "#",
      children: [
        {
          title: "flights",
          icon: PlaneIcon,
          to: "/booking/flight",
        },
        {
          title: "hotel",
          icon: HotelServiceIcon,
          to: "/booking/hotel",
          isHide:
            !hasHotelType.value && // Condition pour cacher si `hasHotelType` est faux
            !auth?.groups?.includes("Metis"),
        },
        // Uncomment and update these items if needed
        // {
        //   title: "Voitures",
        //   icon: CarIcon,
        //   to: "/booking/demographical",
        // },
        // {
        //   title: "Trains",
        //   icon: TrainIcon,
        //   to: "/booking/minimal",
        // },
        // {
        //   title: "Package",
        //   icon: BoxIcon,
        //   to: "/booking/package",
        // },
        // {
        //   title: "Réceptif",
        //   icon: ReceiptIcon,
        //   to: "/booking/receptive",
        // },
      ],
    },
    {
      title: "folders",
      icon: FolderIcon,
      to: "/folder",
    },
    {
      title: "notifications",
      icon: BellIcon,
      to: "/notification",
    },
    {
      title: "crm",
      icon: DashboardIcon,
      to: runtimeConfig.dashboardFront,
    },
    {
      title: "edv",
      icon: Book2Icon,
      to: "#",
      children: [
        {
          title: "jdv",
          icon: Book2Icon,
          to: "/journal/sales-journal",
        },
        {
          title: "uettr",
          icon: Book2Icon,
          to: "/journal/uettr",
          isHide: !isUettr.value,
        },
      ],
    },
    {
      title: "help",
      icon: HelpIcon,
      to: "/help",
    },
  ];
});

export default horizontalItems;
